@import 'libs/styles/src/common/common';

@layer component {
  .landing {
    margin-bottom: 11rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 8rem;
    }
  }

  .landing-heading-container {
    padding: 1rem 0 6rem;
    text-align: center;
    position: relative;
    background-color: $light-grey;

    @include media-breakpoint-up(md) {
      overflow: hidden;
    }

    @include media-breakpoint-down(sm) {
      padding: 1rem 0 4rem;
    }

    @include media-breakpoint-down(xs) {
      padding: 0 0 3rem;
    }

    &::before {
      border-radius: 0 0 100% 100%/200px;
      position: absolute;
      background: $dark-blue;
      right: -200px;
      left: -200px;
      top: -200px;
      content: '';
      bottom: 0;

      @include media-breakpoint-down(sm) {
        border-radius: initial;
        right: 0;
        left: 0;
      }
    }

    & > div {
      position: relative;
    }

    .landing-heading {
      padding: 0 1rem;
    }

    .landing-pin {
      margin-top: -120px;
      margin-bottom: -85px;

      @include media-breakpoint-down(sm) {
        margin-bottom: -65px;
        margin-top: -100px;
        width: 192px;
        height: 220px;
      }
    }

    .general-image {
      width: 200px;
      height: auto;
      margin-bottom: 1rem;

      @include media-breakpoint-down(xs) {
        width: 37vw;
        height: auto;
      }
    }

    .search-header {
      @include media-breakpoint-up(md) {
        width: 705px;
      }

      @include media-breakpoint-only(sm) {
        font-size: 28px;
      }

      @include media-breakpoint-only(xs) {
        font-size: 22px;
      }

      margin: auto;
      color: $white;
      font-weight: normal;
      font-size: 36px;
    }

    .emphasis {
      font-weight: bold;
    }
  }

  .tabs-container {
    margin-top: -5rem;
    margin-bottom: 2rem;
    min-height: 200px;

    @include media-breakpoint-down(sm) {
      min-height: 190px;
      overflow: auto;
      margin-top: -3.25rem;
      margin-bottom: 0.5rem;
    }

    @include media-breakpoint-down(xs) {
      min-height: 160px;
      margin-top: -2.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .tabs {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    max-width: 1100px;
    margin: auto;

    @include media-breakpoint-down(xs) {
      justify-content: start;
    }

    @media (max-width: 372px) {
      & > :first-child {
        padding-left: 1rem;
      }

      & > :last-child {
        padding-right: 1rem;
      }
    }
  }

  .tab {
    font-size: 16px;
    flex: 1;
    padding: 0;
    overflow: visible;
    border: 0;
    background-color: initial;
    display: block;
    margin: 0 0.5rem;
    cursor: pointer;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    @include media-breakpoint-down(sm) {
      position: initial !important;
    }

    &__icon {
      border-radius: 50%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem auto 0;
      width: 5em;
      min-width: 5em;
      height: 5em;
      border: 1px solid #fff0;
      transform: rotateZ(45deg);

      @include media-breakpoint-down(sm) {
        padding: 0.5rem;
        width: 4em;
        min-width: 4em;
        height: 4em;
      }

      @include media-breakpoint-down(xs) {
        padding: 0.5rem;
        width: 3em;
        min-width: 3em;
        height: 3em;
      }

      &__img {
        transform: rotateZ(-45deg);

        @include media-breakpoint-down(sm) {
          &.portrait {
            height: 100%;
            width: auto;
          }

          &.landscape {
            width: 100%;
            height: auto;
          }
        }
      }

      &.selected {
        box-shadow: 0 4px 20px rgb(128 128 128 / 65%);
        border-radius: 50px 50px 5px;
      }
    }

    &__text {
      margin-top: 1rem;
      font-weight: 700;
      text-align: center;
      font-size: 18px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }

      @include media-breakpoint-down(xs) {
        margin-top: 0.5rem;
        font-size: 14px;
      }
    }

    .selector {
      position: relative;
      bottom: 0;
      margin: 1rem auto 0;
      width: 0;
      height: 0;

      --color: #0000;

      border-left: 0.5rem solid #0000;
      border-right: 0.5rem solid #0000;
      border-bottom: 0.6rem solid var(--color);
      border-radius: 2px;
      display: none;

      &.selected {
        display: block;
      }
    }
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem 2rem;

    @include media-breakpoint-up(lg) {
      height: 6em;
    }

    @include media-breakpoint-only(md) {
      max-width: 650px;
      margin: 0 auto 2rem;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 2rem 2rem;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 0.5rem 2rem;
    }

    &__icon {
      border-radius: 50%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6em;
      min-width: 6em;
      height: 100%;
      z-index: 1;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__form {
      background-color: #fff;
      border-radius: 0 48px 48px 0;
      border-width: 1px 1px 1px 0;
      border-style: solid;
      padding: 1rem;
      box-shadow: $small-shadow;
      min-width: 600px;
      min-height: 86px;
      height: 100%;
      position: relative;

      // max-height: 86px;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: stretch;
        padding-left: 4rem;
        margin-left: -3rem;
        min-width: 686px;
      }

      @include media-breakpoint-down(md) {
        min-height: 163px;
        border-width: 2px;
        border-radius: 1rem;
        max-height: unset;
        min-width: unset;
        width: 100%;
      }

      @include media-breakpoint-down(xs) {
        padding: 1rem 0.5rem;
      }
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: bold;
      margin: -0.5rem 1rem -0.5rem 0;

      @include media-breakpoint-down(md) {
        margin: 0 0 1rem;
      }
    }

    .form-container {
      display: flex;
      align-items: center;
      width: 100%;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    .form-seperator {
      border-left: 1px solid $blue;
      align-self: stretch;
      margin: 0.5rem 0;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .form-submit-button {
      font-size: 16px;
      font-weight: 500;
      margin-left: auto;

      @include media-breakpoint-down(md) {
        align-self: flex-end;
      }
    }

    .form-input-text {
      font-size: 1rem;
      font-weight: 300;

      @include media-breakpoint-up(lg) {
        border: none;
      }
    }

    .form-input {
      padding: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 0.5rem;
      }
    }

    .form-input-field {
      min-width: 280px;
      padding: 1.6rem 0.75rem;
      margin: -1rem 0.5rem;

      @include media-breakpoint-down(md) {
        min-width: unset;
        padding: 0.6rem 1.25rem;
        margin: 0;
      }

      @include media-breakpoint-down(xs) {
        padding: 0.6rem 0.5rem 0.6rem 1.25rem;
      }
    }

    .form-secondary-input-field {
      min-width: 200px;
      padding: 1.6rem 0.75rem;
      margin: -1rem 0.5rem;

      @include media-breakpoint-down(md) {
        min-width: unset;
        padding: 0.6rem 1.25rem;
        margin: 0;
      }

      @include media-breakpoint-down(xs) {
        padding: 0.6rem 0.5rem 0.6rem 1.25rem;
      }
    }

    .form-error {
      color: $red;
      padding-left: 1.25rem;
      height: 0;
      margin-top: -3px;
      margin-bottom: 3px;
      font-size: 14px;

      @include media-breakpoint-down(md) {
        height: auto;
        margin-bottom: 0.5rem;
      }
    }

    .form-field {
      display: flex;
      flex-direction: column;
      flex: 1;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .form-field-dropdown {
      @include media-breakpoint-up(lg) {
        top: calc(100% + 1px);
        margin: 0 3rem;
      }
    }
  }
}
